import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import Img from "gatsby-image"
import { Flex } from 'grid-emotion';
import CarouselImage from './CarouselImage';
import CarouselVideo from './CarouselVideo';
import VimeoVideo from './VimeoVideo';
import StyledButton from './StyledButton';
import ReactHtmlParser from 'react-html-parser';

import Vimeo from '@u-wave/react-vimeo';

import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, ButtonFirst, ButtonLast } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';


import navArrow from "../images/arrow_nav.svg"
import theme from '../../config/theme';


const Wrapper = styled.div`
  color: ${props => props.theme.colors.greyDark};
  a {
    color: ${props => props.theme.colors.greyDark};
    &:hover {
      color: ${props => props.theme.colors.primaryLight};
    }
  }
  &.carouselHero{
    margin-bottom:16.4rem;
  }

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    margin-bottom:${props => props.theme.basicModulePadding.l.bottom};

    &.carouselHero{
      margin-bottom:${props => props.theme.basicModulePadding.l.bottom};
    }
  }

  @media (max-width: ${props => props.theme.breakpoint.s}) {
    margin-bottom:2.25rem;
      &.carouselHero{
        margin-bottom:2.25rem;
      }
  }
  position:relative;
  .carousel__slide-focus-ring{
    display:none;
  }
  &, .slider, .carousel__slider-tray-wrapper, .carousel__slider-tray, .carousel__slide {
    width:100vw;
    height:83vh;
    min-height:550px;

    @media (max-width: ${props => props.theme.breakpoint.l}) {
      height:Calc(${props => props.theme.featureHeight.l.height});
      min-height:Calc(${props => props.theme.featureHeight.l.height});
    }
    top:0;
    left:0;
  }

  .videoSlide{
    background-color: ${props => props.theme.colors.lightPink};

  }
  .videoCurtain{
    &:after{
      background:${props => props.theme.colors.red};
    }
  }
  &.tag_augmented-reality{
    .videoCurtain{
      &:after{
        background:${props => props.theme.colors.red};
      }
    }
  }
  &.tag_digital{
    .videoCurtain{
      &:after{
        background:${props => props.theme.colors.yellow};
      }
    }
  }
  &.tag_production{
    .videoCurtain{
      &:after{
        background:${props => props.theme.colors.pink};
      }
    }
  }
   .carousel__slide{
     padding-bottom:0 !important;
   }
   &.carouselHasCaption{
     @media (max-width: ${props => props.theme.breakpoint.s}) {
       height:88vh;
       .carousel, .carousel__slider, .carousel__slider-tray-wrapper, .carousel__slider-tray, .carousel__slide {
         height:100%;
       }
       .hasCaption{
         height:44vh;
       }
       .imageCaption{
         height:Calc(44vh - 15px)
       }
       .carouselNav{
         top:25%;
       }
     }
   }
   &.videoPlaying {
     .sliderHeroMeta, .carouselNav {
       display:none;
     }
   }
`;
// const Slider = styled(Flex)`
//   position:absolute;
// `;
const SliderHeroMeta = styled(Flex)`
  position:absolute;
  bottom:4.5rem;
  left:0;
  width:100vw;
  justify-content: center;
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    bottom:2.88rem;
    pointer-events:none;
    button{
      pointer-events:all;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    bottom:${props => props.theme.basicModulePadding.s.bottom};
    align-items:flex-end;
  }


`;
const SliderTitle = styled.div`
  width:50%;
  padding-left:${props => props.theme.basicModulePadding.left};
  h1, h2, h3, h4, h5, h6{
    color:${props => props.theme.colors.white};
    margin-bottom:0;
  }
  h1{
    ${props => props.theme.font.h1}
    /* font-family: "korolev-condensed", "korelev", sans; */
  }
  h4{
    font-weight: lighter;
    text-transform: none;
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding:0 0 0 ${props => props.theme.basicModulePadding.l.left};
    h1{
      ${props => props.theme.font.h1.l}
    }

    h4{
      ${props => props.theme.font.subtitle.l}
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    left:0;
    top:0;
    bottom:unset;
    width:100%;
    margin:0 auto;
    padding:0 0 0 ${props => props.theme.basicModulePadding.s.left};
    h1{
      ${props => props.theme.font.h1.s}
    }
    h4{
      ${props => props.theme.font.subtitle.s};
    }
  }
`;
const SliderLinks = styled(Flex)`
  width:50%;
  text-align:right;
  padding-right: Calc(${props => props.theme.basicModulePadding.right} + 0.3rem);
  justify-content: flex-end;
  align-items: flex-end;
  .wrapperStyledButton{
    margin-right:1rem;
    &:last-child{
      margin-right:0;
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        margin-left:0.5rem;
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding-bottom:0;
    padding-right: Calc(${props => props.theme.basicModulePadding.l.right} + 0.3rem);
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    padding-right: Calc(${props => props.theme.basicModulePadding.s.right});
    .wrapperStyledButton{
      margin-right:0;
      &:last-child{
        margin-bottom:0;
      }
    }
  }
`;
const CarouselNav = styled.div`
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  width:100%;
  height:68px;
  pointer-events:none;
  button{
    pointer-events:all;
    border:none;
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      &.carousel__back-button, &.carousel__last-button{
        text-align:left;
      }
      &.carousel__next-button, &.carousel__first-button{
        text-align:left;
      }
      img{
        height: 50%;
        width: 50%;
      }
    }
    &.carousel__last-button, &.carousel__first-button{
      display:none;
    }

    &.carousel__next-button, &.carousel__back-button,
    &.carousel__last-button, &.carousel__first-button{
      background-color:transparent;
      padding:0 2rem;
      position:absolute;
      &:disabled {
        opacity: 0.5;
      }
    }
    &.carousel__next-button, &.carousel__first-button{
      transform: scaleX(-1);
      right:0;
    }
  }
  &.first{
    button{
      &.carousel__back-button{
        display:none;
      }
      &.carousel__last-button{
        display:inline-block;
      }
    }
  }
  &.last{
    button{
      &.carousel__next-button{
        display:none;
      }
      &.carousel__first-button{
        display:inline-block;
      }
    }
  }
`;

class Carousel extends React.Component  {
  // ... constructor and toggleMenu from above
  constructor(props) {
    super(props)
    this.state = {videoPaused: true, carouselNavIndex:0, specialSlide:" first"};
    this.handleVideoState = this.handleVideoState.bind(this)
    this.handleCarouselNavClickBack = this.handleCarouselNavClickBack.bind(this)
    this.handleCarouselNavClickForward = this.handleCarouselNavClickForward.bind(this)
    this.handleCarouselNavClickLast = this.handleCarouselNavClickLast.bind(this)
    this.handleCarouselNavClickFirst = this.handleCarouselNavClickFirst.bind(this)
  }

  handleVideoState(videoStatus) {
    this.setState({ videoPaused: videoStatus.paused });

  }

  handleCarouselNavClickLast(event){
    this.setState({ carouselNavIndex: this.props.data.items.length-2 , specialSlide:" last" });
  }
  handleCarouselNavClickFirst(event){
    this.setState({ carouselNavIndex: 0, specialSlide:" first" });
  }
  handleCarouselNavClickBack(event){
    this.setState({ carouselNavIndex: this.state.carouselNavIndex -1 , specialSlide:"" });

    if (this.state.carouselNavIndex < 1){
      this.setState({specialSlide:" first" });
    }
  }
  handleCarouselNavClickForward(event){
    this.setState({ carouselNavIndex: this.state.carouselNavIndex +1, specialSlide:"" });

    if (this.state.carouselNavIndex == this.props.data.items.length-2){
      this.setState({specialSlide:" last" });
    }
  }
  render() {
    const { data, project, slugifiedTag} = this.props;
    let idProperty = {};
    if (data.primary.fragment_id != null){
      idProperty = {id:data.primary.fragment_id};
    }
    let siblingLinkButtons = project.sibling_pages.map((siblingLink, idx) =>{
      let buttonColor = theme.colors.yellow;
      if (siblingLink.link_href != null){
        return (
          <StyledButton key={idx} button_color={buttonColor} link_url={siblingLink.link_href} link_text={ siblingLink.link_label } />
        );
      }else{
        return("");
      }
    });
    let carouselNav = (data.items.length > 1)?
    <CarouselNav className={"carouselNav" + this.state.specialSlide}>
      <ButtonBack disabled={false} onClick={this.handleCarouselNavClickBack}>
        <img src={navArrow} alt="Previous" />
      </ButtonBack>
      <ButtonLast onClick={this.handleCarouselNavClickLast}>
        <img src={navArrow} alt="Previous" />
      </ButtonLast>
      <ButtonNext disabled={false} onClick={this.handleCarouselNavClickForward}>
      <img src={navArrow} alt="Next" />
      </ButtonNext>
      <ButtonFirst onClick={this.handleCarouselNavClickFirst}>
        <img src={navArrow} alt="Next" />
      </ButtonFirst>
    </CarouselNav>:
    "";

    let heroElements = "";
    let wrapperClassName = "";
    if (data.primary.hero_status == "Yes"){
      wrapperClassName = "carouselHero";
      heroElements = <SliderHeroMeta className="sliderHeroMeta">
        <SliderTitle>
          <h1>{ ReactHtmlParser(project.client_name.text) }</h1>
          <h4>{ ReactHtmlParser(project.project_name) }</h4>
        </SliderTitle>
        <SliderLinks>{siblingLinkButtons}</SliderLinks>
      </SliderHeroMeta>
      ;
    }
    let sample = [1, 2, 3];
    let carouselHasCaption = false;
    data.items.map((item, idx) => {
      if (item.carousel_caption.text != null && item.carousel_caption.text != ""){
        carouselHasCaption = true;
      }
    });
    if (carouselHasCaption == true){
      wrapperClassName += " carouselHasCaption";
    }
    let { videoPaused, specialSlide } = this.state;
    return (
      <Wrapper {...idProperty} className={wrapperClassName  + " tag_"+ slugifiedTag + ((videoPaused)?"":" videoPlaying")}>
        <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={83} totalSlides={data.items.length} dragEnabled={false} >
          <Slider className="slider">
              {data.items.map((item, idx) => {
                let videoId = "";
                if (item.video_id && item.video_id.text){
                  videoId = item.video_id.text;
                  return <Slide key={idx} index={idx} className="videoSlide"><VimeoVideo parentVideoHandler={this.handleVideoState} videoId={videoId} imageObject={item.carousel_image} /></Slide>
                }
                if (item.carousel_image && item.carousel_image.url != null){
                    return <Slide key={idx} index={idx}><CarouselImage {...item} isHero={data.primary.hero_status} key={idx} /></Slide>
                }
                // else if (item.video_embed && item.video_embed.text != null){
                //   console.log("here");
                //     return <Slide key={idx} index={idx}><CarouselVideo  {...item} key={idx} /></Slide>
                // }
              })
            }
          </Slider>
          {carouselNav}
        </CarouselProvider>
        {heroElements}
      </Wrapper>
    )
  }
}
export default Carousel;


Carousel.propTypes = {
  data: PropTypes.object.isRequired,
  project: PropTypes.object,
  slugifiedTag: PropTypes.string
};
