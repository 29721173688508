import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import slugify from 'slugify';

import Vimeo from '@u-wave/react-vimeo';

import ImageCaption from './ImageCaption';
import VimeoVideo from './VimeoVideo';
import StaticImage from './StaticImage';

const WrapperContentSection = styled(Flex)`
  width: 100vw;
  height: auto;
  position: relative;
  .col {
    &.mobile {
      display: none;
      line-height: 0;
    }
  }
  .contentSectionProject {
    .col {
      @media (max-width: ${props => props.theme.breakpoint.l}) {
        .sectionHeading,
        &.titleOnly {
          display: none;
        }
        &.mobile {
          display: inline-block;
          .sectionHeading {
            display: inline-block;
          }
        }
      }
    }
  }
  &.boxAlignment_right {
    .contentSectionBackground {
      right: 0;
      img {
        object-position: right center !important;
      }
    }
    .contentSectionSection {
      @media (max-width: ${props => props.theme.breakpoint.l}) {
        flex-direction: column;
        .col {
          &.mobile {
            order: -1000;
          }
        }
      }
    }
    &.contentSectionCategory {
      .contentSectionSection {
        .sectionHeading {
          h1 {
            margin-left: 0;
          }
        }
      }
    }
  }
  &.boxAlignment_left {
    .contentSectionSection {
      flex-direction: row-reverse;
      @media (max-width: ${props => props.theme.breakpoint.l}) {
        flex-direction: column-reverse;
        .col {
          &.mobile {
            order: 1000;
          }
        }
      }
    }
    .contentSectionBackground {
      left: 0;
      img {
        object-position: left center !important;
      }
    }

    &.contentSectionCategory {
      .contentSectionBackground {
        left: 0;
        img {
          object-position: left center !important;

          @media (max-width: ${props => props.theme.breakpoint.l}) {
            object-position: right center !important; /*added for alignment on TagCategory page */
          }
        }
      }
      .sectionHeading {
        h1 {
          margin-left: -7.2rem;
        }
      }
    }
  }
  &.boxAlignment_vertical {
    .contentSectionSection {
      flex-direction: column;
      .col {
        width: 100%;
      }
    }
  }
  &.contentSectionProject {
    .col_single {
      &.contentSectionSection {
        .sectionVideo {
          position: relative;
          background-color: ${props => props.theme.colors.greyDark};
        }
      }
    }
    /* min-height: 100vh; */
    h1 {
      ${props => props.theme.font.h2}
      letter-spacing:0.1em;
      margin-bottom: 1.66rem;
      @media (max-width: ${props => props.theme.breakpoint.l}) {
        ${props => props.theme.font.h1.l}
        margin-bottom:1.66rem;
      }
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        ${props => props.theme.font.h2.l}
        margin-bottom:0.54rem;
      }
    }
    &.hasBackground {
      margin-top: 0;

      margin-bottom: 3.33rem;
      .contentSectionSection{
        margin-top:6.66rem;
        @media (max-width: ${props => props.theme.breakpoint.l}) {
          margin-top:2.22rem;
          margin-bottom:2.22rem;
        }
      }
    }
    .contentSectionSection {
      z-index: 1;
      margin-left: ${props => props.theme.basicModulePadding.left};
      margin-right: ${props => props.theme.basicModulePadding.right};
      margin-top: 0;
      margin-bottom: 6.66rem;
      justify-content: space-between;
      align-items: center;
      @media (min-width: ${props => props.theme.breakpoint.l}) {
        max-width: calc(
          100vw - ${props => props.theme.basicModulePadding.left} - ${props => props.theme.basicModulePadding.right}
        );
      }

      @media (max-width: ${props => props.theme.breakpoint.l}) {
        margin: ${props => props.theme.basicModulePadding.l.top} ${props => props.theme.basicModulePadding.l.left} 0;
      }
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        width: 100%;
        margin: 0;
        margin: 0.33rem ${props => props.theme.basicModulePadding.s.left} 0;
      }
      h1 {
        @media (max-width: ${props => props.theme.breakpoint.m}) {
          letter-spacing: 0;
        }
      }
    }

    .contentSectionBackground {
      position: absolute !important;
      overflow: hidden;
      display: inline-block;
      width: 25vw !important;
      height: 150vh !important;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
      img {
        object-fit: contain !important;
      }
      &:before,
      &:after {
        height: 100%;
        background-size: contain;
        background-position-x: left;
      }
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        width: 75vw !important;
        img {
          object-position: -25vw center !important;
        }
      }
    }

    &.boxAlignment_right {
      .contentSectionBackground {
        @media (max-width: ${props => props.theme.breakpoint.s}) {
          img {
            object-position: 25vw center !important;
          }
        }
      }
    }
    .imageCaption {
      width: 75%;
      left: 27.5%;
      bottom: 0;
      transform: translateY(35%);
      @media (max-width: ${props => props.theme.breakpoint.m}) {
        width: 100%;
        left: 0;
        bottom: unset;
        transform: translateY(0);
        position: relative;
      }
    }
    /*project only*/
    .sectionSubheading {
      h2 {
        ${props => props.theme.font.h3};
        margin-bottom: 1.22rem;
        @media (max-width: ${props => props.theme.breakpoint.l}) {
          ${props => props.theme.font.h3.l}
        }
      }
    }
    .sectionCopy {
      ${props => props.theme.font.paragraphCopy};
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        ${props => props.theme.font.paragraphCopy.s};
      }
    }
  }
  &.contentSectionCategory {
    .contentSectionBackground {
      position: absolute !important;
      overflow: hidden;
      display: inline-block;
      width: 32.5vw !important;
      height: 100% !important;
      top: 50%;
      transform: translateY(-50%);
      z-index: 0;
      @media (max-width: ${props => props.theme.breakpoint.l}) {
        width: 50vw !important;
      }
      img {
        object-fit: cover !important;
        object-position: right center !important;
      }

      @media (max-width: ${props => props.theme.breakpoint.s}) {
        /* display:none !important; */
      }
    }
    &.category_work {
      .contentSectionBackground {
        width:45vw !important;
      img{
         object-fit:cover !important;
         object-position: left bottom !important;
         @media (max-width: ${props => props.theme.breakpoint.l}) {
           /* object-position: left bottom !important; */
         }
       }
      }
      &.contentSectionCategory{
        min-height:124vh;

        @media (max-width: ${props => props.theme.breakpoint.l}) {
          min-height: 100vh;
        }
        .contentSectionSection {
          width: Calc(55vw + ${props => props.theme.basicModulePadding.left});
          align-items: flex-start;
          padding-top: 36vh;
          justify-content: center;
          @media (max-width: ${props => props.theme.breakpoint.l}) {
            width: 100%;
            padding-top: 3.33rem;
            align-items: center;
          }

          @media (max-width: ${props => props.theme.breakpoint.s}) {
            padding-top:0;
            width:60%;
            padding-left:0;
            margin-left:${props => props.theme.basicModulePadding.s.left};
          }
        }
      }
    }
    min-height: 83vh;
    min-height: 108vh;
    @media (max-width: ${props => props.theme.breakpoint.l}) {
      min-height: 55vh;
    }

    .contentSectionSection {
      align-items: center;
      position: relative;
      @media (max-width: ${props => props.theme.breakpoint.xl}) {
        padding-top: 4.44rem;
        padding-bottom: 4.44rem;
      }
    }
    &.boxAlignment_left {
      .contentSectionSection {
        margin-left: 25vw;
        width: 64vw;
        padding-right: 5.55rem;
      }
    }
    &.boxAlignment_right {
      .contentSectionSection {
        padding: 7.4rem 6.4rem 7.4rem 6.4rem;
      }
    }
    &.boxAlignment_left,
    &.boxAlignment_right {
      .contentSectionSection {
        @media (max-width: ${props => props.theme.breakpoint.l}) {
          width: 71vw;
          padding-right: ${props => props.theme.basicModulePadding.l.left};
        }
        @media (max-width: ${props => props.theme.breakpoint.s}) {
          margin-left:0;
          width:100%;
          padding-left:${props => props.theme.basicModulePadding.s.left};
          padding-right:${props => props.theme.basicModulePadding.s.right};
        }
      }
    }
    .contentSectionBackground {
      display: flex;
      align-items: center;
    }
    h1,
    h2 {
      /* font-family: "korolev-condensed", "korelev", sans; */
    }
    /*category specific*/
    .sectionHeading {
      h1 {
        ${props => props.theme.font.h1}
        letter-spacing:0.05em;
        margin-bottom: 1.4rem;
        @media (max-width: ${props => props.theme.breakpoint.l}) {
          margin-bottom: 0.5rem;
          ${props => props.theme.font.h1.l}
        }
        @media (max-width: ${props => props.theme.breakpoint.m}) {
          margin-left: 0 !important;
        }
        @media (max-width: ${props => props.theme.breakpoint.s}) {
          letter-spacing: 0.1rem;
          word-wrap: normal;
          ${props => props.theme.font.h1.s_alt};
        }
      }
    }

    h1 {
      color: ${props =>
        props.bgcolor && props.bgcolor === 'Light-Pink'
          ? props.theme.colors.yellow
          : props.bgcolor === 'Black'
          ? props.theme.colors.lightPink
          : props.bgcolor === 'White'
          ? props.theme.colors.red
          : props.theme.colors.white};
    }
    &.category_digital {
      h1 {
        color: ${props => props.theme.colors.yellow};
      }
    }
    &.category_augmented-reality {
      h1 {
        color: ${props => props.theme.colors.pink};
      }
    }
    &.category_production {
      h1 {
        color: ${props => props.theme.colors.lightPink};
      }
    }
    /*Category only */
    .sectionSubheading {
      h2 {
        ${props => props.theme.font.largeBody};
        margin-bottom: 1.22rem;

        @media (max-width: ${props => props.theme.breakpoint.l}) {
          ${props => props.theme.font.largeBody.l};
          margin-bottom: 0.55rem;
        }
        @media (max-width: ${props => props.theme.breakpoint.s}) {
          ${props => props.theme.font.h3.s};
          line-height: 1rem;
        }
      }
    }
    .sectionCopy {
      ${props => props.theme.font.subtitle};
      line-height: 2.22rem;
      @media (max-width: ${props => props.theme.breakpoint.l}) {
        ${props => props.theme.font.subtitle.l};
      }
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        ${props => props.theme.font.paragraphCopy.s};
      }
    }
  }
  .project {
    color: ${props => props.theme.colors.white};
    min-height: calc(100vh - 8rem);
    h3,
    h4 {
      margin-bottom: 0.2rem;
      color: ${props => props.theme.colors.white};
    }
  }
`;

const ContentSectionSection = styled(Flex)`
  align-items: flex-start;
  /* padding: 200px 0; */
  width: Calc(100vw - 15.54rem);

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    width: 100%;
  }
  &.col_double {
    .col {
      width: Calc(100% / 2 - 1.3rem);

      .sectionVideo {
        min-height: 43vh;
        @media (max-width: ${props => props.theme.breakpoint.s}) {
          min-height: 28vh;
        }
      }
      @media (max-width: ${props => props.theme.breakpoint.l}) {
        width: 100%;
      }
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        margin-bottom: 1rem;
      }
    }
  }

  &.col_single {
    .col {
      width: 100%;

      .sectionVideo {
        min-height: 83vh;
        @media (max-width: ${props => props.theme.breakpoint.m}) {
          min-height: Calc(${props => props.theme.featureHeight.l.height} - 2.88rem);
          height: Calc(${props => props.theme.featureHeight.l.height} - 2.88rem);
        }
        @media (max-width: ${props => props.theme.breakpoint.s}) {
          min-height: 20vw;
        }
      }
    }
  }
`;

const Heading = styled(Flex)`
  height: 8rem;
  background-color: ${props => (props.bgcolor ? props.bgcolor : props.theme.colors.greyDark)};
  align-items: center;
  h2 {
    color: ${props =>
      props.bgcolor && props.bgcolor === '#282828' ? props.theme.colors.white : props.theme.colors.greyDark};
    margin-bottom: 0;
  }
`;
const StyledLink = styled(Link)`
  transform: translateX(0);
  transition: all 200ms ease-out;
  padding: 1rem;
  line-height: 1rem;
  font-size: 1rem;
  height: 3rem;
  background-color: ${props => (props.bgcolor ? props.bgcolor : props.theme.colors.greyDark)};
  color: ${props => (props.bgcolor === '#282828' ? props.theme.colors.white : props.theme.colors.greyDark)};
  &:hover {
    color: ${props => props.theme.colors.bg};
    transform: translateX(-6px);
  }
`;

const SectionText = styled.div``;
const SectionHeading = styled.div``;
const SectionSubheading = styled.div``;
const SectionCopy = styled.div`
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    font-size: 1.11rem;
  }
`;
const SectionMedia = styled(Flex)`
  flex-wrap:wrap;
  justify-content:space-between;
  .sectionMedia.sectionVideo{
    margin-bottom:0.833rem;
    &:last-child{
      margin-bottom:0;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    flex-direction: column;
    min-width: 100%;
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    .sectionMedia:first-child{
      margin-top:0;
    }
    .sectionMedia:last-child{
      padding-bottom:0;
      margin-bottom:0.833rem;
    }
  }
`;
const SectionImage = styled.div`
  padding-bottom: 0.833rem;

  .gatsby-image-wrapper {
    height: 100%;
  }
  &.hasCaption {
    position: relative;
    @media (max-width: ${props => props.theme.breakpoint.l}) {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: ${props => props.theme.breakpoint.m}) {
    width: 100% !important;
  }
`;
const SectionVideo = styled.div`
  width: 100%;
  &.hasBackground {
    min-height: 23.5vw;
  }
  margin-bottom:0;
  background-color:transparent;

  @media (max-width: ${props => props.theme.breakpoint.s}) {
    margin-bottom:0.833rem;
  }

`;
const Gallery = styled(Flex)``;
class ContentSection extends React.Component {
  // const ContentSection = ({tag, isCategory, data }) => {
  constructor(props) {
    super(props);
    this.state = { paused: true, muted: false };

    this.handlePause = this.handlePause.bind(this);
    this.handlePlayerPause = this.handlePlayerPause.bind(this);
    this.handlePlayerPlay = this.handlePlayerPlay.bind(this);
  }

  handlePause(event) {
    this.setState({ paused: !this.state.paused });
    this.setState({ muted: false });
  }

  handlePlayerPause(event) {
    this.setState({ paused: true });
    this.setState({ muted: false });
  }

  handlePlayerPlay(event) {
    this.setState({ paused: false });
    this.setState({ muted: false });
  }

  handleLoad(event) {
    this.setState({ paused: true });
    this.setState({ muted: false });
  }

render(){
  const {tag, isCategory, data } = this.props;

  const { paused, muted } = this.state;

  const hasText = ((data.primary.heading.text != "" || data.primary.sub_heading.text != "" || data.primary.section_copy.text != "") && (data.primary.heading.text != null || data.primary.sub_heading.text != null || data.primary.section_copy.text != null));
  const titleOnly = ((data.primary.heading.text != "" && data.primary.heading.text != null) && (data.primary.sub_heading.text != "" || data.primary.section_copy.text != "" || data.primary.sub_heading.text != null || data.primary.section_copy.text != null));
  const hasMedia = data.items && data.items.length > 0;

  const sectionContentColumns = (hasText && hasMedia)?"col_double":"col_single";

  const hasBackground = (data.primary.background_image.localFile != null && data.primary.background_image.localFile.childImageSharp != null);
  const projectClassname = (isCategory == true)?" contentSectionCategory category_"+slugify(tag):" contentSectionProject";
  // let sectionVideoPlayer = new Player();
  // const player = new Player('handstick', {
  //     id: 19231868,
  //     width: 640
  // });
  const content = (
    <ContentSectionSection className={"contentSectionSection " +" "+ sectionContentColumns}>
      <div className="col mobile">
        <SectionHeading className="sectionHeading">
          {ReactHtmlParser(data.primary.heading.html)}
        </SectionHeading>
      </div>
      {hasText &&
        <SectionText className={(titleOnly)?"col titleOnly":"col"}>
          {data.primary.heading.text != "" && data.primary.heading.text != null &&
            <SectionHeading className="sectionHeading">
            {ReactHtmlParser(data.primary.heading.html)}
            </SectionHeading>
          }
          {data.primary.sub_heading.text != "" && data.primary.sub_heading.text != null &&
            <SectionSubheading className="sectionSubheading">
            {ReactHtmlParser(data.primary.sub_heading.html)}
            </SectionSubheading>
          }
          {data.primary.section_copy.text != "" && data.primary.section_copy.text != null &&
            <SectionCopy className="sectionCopy">
            {ReactHtmlParser(data.primary.section_copy.text)}
            </SectionCopy>
          }
        </SectionText>
      }
      {hasMedia &&
        <SectionMedia className="col">
        {
          data.items.map((item, idx) => {
            let hasVideo = (item.video_id && item.video_id.text != null && item.video_id.text != "");

            let hasImage = item.gallery_image.url != null;
            let itemWidth = {
              width: (item.percentage_width != "100%")?"Calc(" + item.percentage_width + " - 0.4165rem)":item.percentage_width
            };

            let hasCaption = false;
            let caption = "";
            if (item.image_caption){
              hasCaption = (item.image_caption["text"] != "" && item.image_caption["text"] != null);
            }


            if (hasImage && !hasVideo) {
              return (
                <SectionImage key={idx} style={itemWidth} className={" sectionMedia" + ((hasCaption)?"hasCaption":"")}>
                  {item.gallery_image.localFile.childImageSharp &&
                    <Img fluid={item.gallery_image.localFile.childImageSharp.fluid} />
                  }
                  {!item.gallery_image.localFile.childImageSharp && (
                      <StaticImage image_src={item.gallery_image.url} />
                  )}
                  {hasCaption &&
                    <ImageCaption className="imageCaption" data={item.image_caption}></ImageCaption>
                  }
                </SectionImage>
              )
            }
            if (hasVideo) {
              // { ReactHtmlParser(item.video_embed.text) }
              // <Vimeo video="232539679" color="#e5a300" background autoplay="0" />
            // this.ifr.onload = () => {
            //   this.ifr.contentWindow.postMessage('hello', "*");
            // }
            //<iframe sandbox="allow-scripts" refs={myRef} src="https://player.vimeo.com/video/232539679?title=0&byline=0&portrait=0&app_id=122963&background=true&autoplay=false"></iframe>
              // let testText = "<iframe src=\"https://player.vimeo.com/video/232539679?title=0&byline=0&portrait=0&app_id=122963&background=true&autoplay=false\" frameborder=\"0\" title=\"Colour Grade Test\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>";
              return (
                <SectionVideo className="sectionVideo sectionMedia" key={idx} >
                  <VimeoVideo key={idx} videoId={item.video_id.text} imageObject={item.gallery_image} style={itemWidth} className={"sectionVideo "+((hasBackground)?"hasBackground":"")} />
                </SectionVideo>

              )
            }
          })
        }
        </SectionMedia>
      }
    </ContentSectionSection>
  ); // end content
  return (
      <WrapperContentSection id={data.primary.fragment_id} bgcolor={slugify(data.primary.box_colour)} className={((hasBackground)?"hasBackground ":"") +"boxAlignment_"+data.primary.media_alignment+ " boxColor_"+slugify(data.primary.box_colour) + projectClassname }>
        {hasBackground &&
          <Img className="contentSectionBackground" fluid={data.primary.background_image.localFile.childImageSharp.fluid}>
          </Img>
        }
        { content }
      </WrapperContentSection>
    );
  }
}

export default ContentSection;

ContentSection.propTypes = {
  data: PropTypes.object.isRequired,
  isCategory: PropTypes.bool,
  tag: PropTypes.string,
};
