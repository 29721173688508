import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import StaticImage from './StaticImage';

const Item = styled(Flex)`
  flex-basis: calc(99.9% - 4rem);
  max-width: calc(99.9% - 4rem);
  width: calc(99.9% - 4rem);
  text-align: center;
  position: relative;
`;

const ProjectOverviewModule = styled(Flex)`
  display: flex;
  flex-wrap: nowrap;
  padding: 0 ${props => props.theme.basicModulePadding.right} 12rem ${props => props.theme.basicModulePadding.left};
  justify-content: space-between;
  &.reversed {
    flex-direction: row-reverse;
  }

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    flex-direction: column;
  }
  .projectOverviewCopy,
  .projectImage {
    width: Calc(100% / 2 - 1.3rem);
    @media (max-width: ${props => props.theme.breakpoint.l}) {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  &.noImage .projectOverviewCopy {
    width: 100%;
  }
  .projectImage {
    @media (max-width: ${props => props.theme.breakpoint.l}) {
      width: Calc(100% - 1.38rem);
    }
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      width: Calc(100% - 0.66rem);
    }
  }
  table {
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      margin-top: 2rem;
      h5 {
        margin-bottom: 0;
      }
    }
    &,
    & h5 {
      font-size: 1.66rem;

      @media (max-width: ${props => props.theme.breakpoint.l}) {
      }
      @media (max-width: ${props => props.theme.breakpoint.s}) {
        ${props => props.theme.font.paragraphCopy.s}
      }
      letter-spacing: 0;
      tr {
        border-bottom: 3px solid ${props => props.theme.colors.yellow};

        &:last-child {
          border-bottom: none;
        }
        td {
          padding-top: 2.35rem;
          padding-bottom: 1rem;

          border-bottom: none;
          p {
            font-family: 'korolev-condensed', 'korolev', sans;
            ${props => props.theme.font.paragraphCopy};
            font-weight: 100;
            text-transform: uppercase;
            @media (max-width: ${props => props.theme.breakpoint.s}) {
              ${props => props.theme.font.h3.s}
              margin-bottom:0;
            }
          }
        }
      }
    }
  }
  .projectDescriptionImage {
    position: relative;
    .manualPadding {
      display: inline-block;
      width: 100%;
      padding-bottom: 56.25%;
    }
    .manualImage {
      position: absolute;
      left: 0;
      top: 0;
    }
    box-shadow: 1.66rem 1.66rem ${props => props.theme.colors.pink};
    @media (max-width: ${props => props.theme.breakpoint.l}) {
      box-shadow: 1.38rem 1.38rem ${props => props.theme.colors.pink};
    }
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      box-shadow: 0.66rem 0.66rem ${props => props.theme.colors.pink};
    }
  }

  @media (max-width: ${props => props.theme.breakpoint.l}) {
    padding: ${props => props.theme.basicModulePadding.l.top} ${props => props.theme.basicModulePadding.l.left};
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    padding: 0.22rem ${props => props.theme.basicModulePadding.s.right} ${props => props.theme.basicModulePadding.s.top}
      ${props => props.theme.basicModulePadding.s.left};
    width: 100%;
    .projectOverviewCopy,
    .projectOveriewImage {
      width: 100%;
    }
    .projectImage {
      padding-top: 2.22rem;
    }
    table {
      tr {
        td {
          width: 50%;
          padding: 0.77rem 0;
          margin: 0;
        }
      }
    }
  }
`;
const Heading = styled.div`
  color: ${props => props.theme.colors.text};
  text-transform: uppercase;
  margin-bottom: 1rem;
  h3 {
    ${props => props.theme.font.largeBody};
    margin-bottom: 1.66rem;
    @media (max-width: ${props => props.theme.breakpoint.l}) {
      ${props => props.theme.font.h1.l};
    }

    @media (max-width: ${props => props.theme.breakpoint.s}) {
      margin-bottom: 1.11rem;
      ${props => props.theme.font.h2.l}
    }
  }
  hr {
    width: 46px;
    height: 5px;
    background-color: ${props => props.theme.colors.currentRed};
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      width: 24px;
      height: 4px;
      margin-bottom: 0.88rem;
    }
  }
`;
const ProjectDescription = styled.div`
  p {
    ${props => props.theme.font.paragraphCopy};
    @media (max-width: ${props => props.theme.breakpoint.l}) {
    }
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      ${props => props.theme.font.paragraphCopy.s};
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const ProjectProperties = styled.div`
  a{
    padding: 0.5rem 0.7rem;
    border: 2px solid ${props => props.theme.colors.currentRed};
    font-size:1.16rem;
    font-family: "korolev-condensed", "korelev", sans;
    font-weight:bold;
    text-transform: uppercase;
    margin-right:1rem;
    @media (max-width: ${props => props.theme.breakpoint.l}) {
      display: inline-block;
      margin-bottom: 1rem;
      padding: 0.65rem 1rem;
      line-height:1rem;
    }
    @media (max-width: ${props => props.theme.breakpoint.s}) {
      ${props => props.theme.font.subtitle.s}
      padding:0.44rem 0.44rem;
      margin-bottom: 0.5rem;
      margin-right:0.5rem;
    }
  }
}
`;
const renderTable = rows => 
  // console.log(rows);
  /*
  if(rows[0].table_description) {
    return null;
  } else {
  */
     (
      <table>
        <tbody>
          {rows.map((row, idx) => {
            let stripHttp = "";
            if (row.table_description.html != null){
                stripHttp = row.table_description.html.split('https://').join('');
            }
            return (
              <tr key={idx}>
                <td>{ ReactHtmlParser(row.table_heading.html) }</td>
                <td>{ ReactHtmlParser(stripHttp) }</td>
              </tr>
            )
          })}
      </tbody>
    </table>
    )
  /*}*/
;
const ProjectTable = ({ heading, description, rows, project_image, isPortfolio, data }) => (
  <ProjectOverviewModule
    className={(isPortfolio === true ? 'reversed' : '') + (!project_image.localFile ? ' noImage' : '')}
    id={data.primary.fragment_id}
  >
    <div className="projectOverviewCopy">
      <Heading className="projectTitle">
        <h3>Project Description</h3>
        <hr />
      </Heading>

      {description.html && (
        <ProjectDescription className="projectDescription">{ReactHtmlParser(description.html)}</ProjectDescription>
      )}
      {rows.length > 0 && rows[0].table_heading.text && (
        <ProjectProperties className="projectProperties">{renderTable(rows)}</ProjectProperties>
      )}
    </div>
    {project_image.localFile && (
      <div className="projectImage">
        {project_image.localFile && project_image.localFile.childImageSharp && (
          <Img className="projectDescriptionImage" fluid={project_image.localFile.childImageSharp.fluid} />
        )}
        {project_image.localFile && !project_image.localFile.childImageSharp && (
          <div className="projectDescriptionImage">
            <div className="manualPadding" />
            {/* <img className="manualImage" src={project_image.url} /> */}
            <StaticImage image_src={project_image.url} />
          </div>
        )}
      </div>
    )}
  </ProjectOverviewModule>
);

export default ProjectTable;

ProjectTable.propTypes = {
  heading: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  project_image: PropTypes.object,
  isPortfolio: PropTypes.bool,
  data: PropTypes.object,
};
