import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import Vimeo from '@u-wave/react-vimeo';
import ReactHtmlParser from 'react-html-parser';

const SlideWrapper = styled(Flex)`
  text-align: center;
  position: relative;
  background-color:${props => props.theme.colors.greyDark};
  &, iframe{
    width:100%;
    height:100%;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    margin-top: 3rem !important;
  }
`;

const CarouselVideo = ({video_embed}) => (
  <SlideWrapper flexDirection='column' className="slide">
    { ReactHtmlParser(video_embed.text) }
  </SlideWrapper>
);

export default CarouselVideo;

CarouselVideo.propTypes = {
  video_embed: PropTypes.object.isRequired
};
