/*

A component to be displayed on the bottom of the Project leaf pages that allows for inter-project navigation: next and previous.

This is meant to work based on the current tag of the project

*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { Link } from 'gatsby';
import { Flex } from 'grid-emotion';


import navArrow from "../images/arrow_nav.svg"

const Wrapper = styled.nav`
  &.hide{
    display:none !important;
  }
  display:flex;
  justify-content: space-between;
  align-items:center;
  width: Calc(100% - ${props => props.theme.basicModulePadding.l.left} - ${props => props.theme.basicModulePadding.l.right});

  box-size:border-box;
  flex-direction:column;
  margin: 5.11rem auto;
  color: ${props => props.theme.colors.greyDark};
  min-height:5rem;
  box-sizing:border-box;
  position:relative;
  background:white;
  border: 1px solid ${props => props.theme.colors.yellow};
  flex-direction:row;

  @media (min-width: ${props => props.theme.breakpoint.l}) {
    width: Calc(100vw - 15.76rem);
    min-height:7.22rem;
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    width: Calc(100% - ${props => props.theme.basicModulePadding.s.left} - ${props => props.theme.basicModulePadding.s.right});
    min-height:3.3rem;
  }
  a {
    color: ${props => props.theme.colors.greyDark};
    &:hover {
      color: ${props => props.theme.colors.primaryLight};
    }
  }
`;

const StyledLink = styled.div`
  transform: translateX(0);
  transition: all 100ms ease-out;
  padding: 0;
  background: white;
  position: relative;

  &:last-child{
  }
  font-size:1rem;
  font-family: ${props => props.theme.fontFamily.heading};
  font-weight:bold;
  text-transform:uppercase;
  display:flex;
  align-items:center;
  &:hover {
    color: ${props => props.theme.colors.bg};
    transform: translateX(-6px);
    &.buttonPrevious{
      transform: translateX(6px);
    }

    .navArrow{
      background-color:${props => props.theme.colors.lightPink};
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    ${props => props.theme.font.h3.s}
  }
  .navArrow{
    display: inline-block;
    width: 2.76rem;
    height: 2.76rem;
    padding:0.35rem;
    background-color:${props => props.theme.colors.pink};
    border-radius:100%;
    cursor:pointer;
    transition: all 100ms ease-out;
    transform:translateX(-50%);
    img{
      width:100%;
      height:100%;
      padding-right:0.2rem;
      fill: ${props => props.theme.colors.red};
      object-fit:contain;
      filter:invert(39%) sepia(92%) saturate(7491%) hue-rotate(7deg) brightness(104%) contrast(106%);
    }

  }

  a{
    margin-left:-0.35rem;
  }
  &.buttonPrevious{
    a{
      margin-left:0;
      margin-right:-0.35rem;
    }
    .navArrow{
      img{
        transform:scaleX(-1);
      }

      transform:translateX(50%);
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.s}) {
    a{
      margin-left:0.15rem;
    }
    &.buttonPrevious{
      a{
        margin-right:0.15rem;
        margin-left:0;
      }
    }
    .navArrow{
      width: 1.88rem;
      height: 1.88rem;
    }
  }
`;

const ProjectSiblingNavigation = ({ uid, tag, linkPrevious, linkNext }) => {

  return (
    <Wrapper className={(!linkPrevious && !linkNext)?"hide":""}>
      <StyledLink className="buttonNext">
      {linkPrevious &&
        <div className="navArrow" >
          <img src={navArrow} />
        </div>
      }
      {linkPrevious &&
        <Link to={linkPrevious}>View Previous Project</Link>
      }
      </StyledLink>
      <StyledLink className="buttonPrevious" >
        {linkNext &&
          <Link to={linkNext}>View Next Project</Link>
        }
        {linkNext &&
          <div className="navArrow">
          <img src={navArrow} />
          </div>
        }
      </StyledLink>
    </Wrapper>
  );
};

export default ProjectSiblingNavigation;

ProjectSiblingNavigation.propTypes = {
  uid: PropTypes.string.isRequired,
  linkPrevious: PropTypes.string,
  linkNext: PropTypes.string,
  tag:PropTypes.array
};

ProjectSiblingNavigation.defaultProps = {

};
