import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import BackgroundImage from 'gatsby-background-image';

import Vimeo from '@u-wave/react-vimeo';

import videoButton from '../images/Video-Button.png';
const VimeoVideoWrapper = styled.div`
  width: 100%;
  height:100%;
  position:relative;
  & > div:first-child{

    width: 100%;
    height: 100%;
    display:flex;
    margin-bottom:0;
  }
  iframe{
    position:relative !important;
    width:100% !important;
    height:100% !important;
    margin-bottom:0;
    pointer-events:all;
  }
  .togglePlayback{
    width:100%;
    height:100%;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index:1;
    pointer-events:all;
    &.hidden{
      display:none;
    }
    .videoCurtain{
        width:100%;
        height:100%;
        cursor:pointer;
        &:after{
          position:absolute;
          top:0;
          left:0;
          z-index:100;
          pointer-events:none;
          opacity:0.25;
          transition-duration:0.25s;
        }
        &:hover{
          &:after{
            opacity:0.25;
          }
        }
        img.videoButton{
          position:absolute;
          top:50%;
          left:50%;
          transform:translate(-50%, -50%);
        }
    }
    img.videoButton{
      object-fit:contain;
      width:25%;
      height:25%;
      object-position:center center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

class VimeoVideo extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {paused: true, muted:false};

    this.handlePause  = this.handlePause.bind(this);
    this.handlePlayerPause = this.handlePlayerPause.bind(this);
    this.handlePlayerPlay = this.handlePlayerPlay.bind(this);
  }
  handlePause(event) {
    // console.log("EVENT");
    this.setState({ paused: !this.state.paused });
    if(this.props.parentVideoHandler){
      this.props.parentVideoHandler({"paused":this.state.paused});
    }
    this.setState({ muted: false });
  }
  handlePlayerPause(event) {
    if(this.props.parentVideoHandler){
      this.props.parentVideoHandler({"paused":true});
    }
    this.setState({ paused: true });
    this.setState({ muted: false });
  }
  handlePlayerPlay(event) {
    if(this.props.parentVideoHandler){
      this.props.parentVideoHandler({"paused":false});
    }
    this.setState({ paused: false });
    this.setState({ muted: false });
  }
  handleLoad(event) {
    if(this.props.parentVideoHandler){
      this.props.parentVideoHandler({"paused":true});
    }
    this.setState({ paused: true });
    this.setState({ muted: false });
  }
  //({ itemWidth, hasBackground, carousel_image, project_video }) => (
  render(){
    const {imageObject, videoId } = this.props;
    const { paused, muted } = this.state;

    let hasImage = imageObject && imageObject.url != null;
    return(
      <VimeoVideoWrapper>
        <Vimeo video={videoId} color={"#e5a300"} onPause={this.handlePlayerPause} onPlay={this.handlePlayerPlay} showTitle={false} showPortrait={false} showByline={false} muted={muted} autoplay={false} loop={true} paused={paused} />
        <div onClick={this.handlePause} className={"togglePlayback" + ((paused)?"":" hidden")}>

        {hasImage &&
          <BackgroundImage className="videoCurtain" fluid={imageObject.localFile.childImageSharp.fluid}>
          <img className="videoButton" src={videoButton} alt="Play" />
          </BackgroundImage>
        }
        {!hasImage &&
          <img className="videoButton" src={videoButton} alt="Play" />
        }
        </div>
      </VimeoVideoWrapper>
    )
  }
}

export default VimeoVideo;

VimeoVideo.propTypes = {
  imageObject: PropTypes.object.isRequired,
  videoId:PropTypes.string.isRequired
};
