import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'react-emotion';
import { Flex } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';

import ImageCaption from '../components/ImageCaption';

const Item = styled(Flex)`
  flex-basis: 100%;
  position: relative;
  height:83vh;
  &.hasCaption{
    height:Calc(83vh - 50px);
  }
  .carouselImg{
    height:100%;
  }
  @media (max-width: ${props => props.theme.breakpoint.l}) {
    flex-basis: 100%;
    max-width: 100%;
    ${props => props.theme.featureHeight.l}
    min-height:${props => props.theme.featureHeight.l.height};
    &.hasCaption{
      height:Calc(${props => props.theme.featureHeight.l.height} - 2.88rem - 50px);
      min-height:Calc(${props => props.theme.featureHeight.l.height} - 50px);
      position:static;
    }
    width: 100%;
  }

  @media (max-width: ${props => props.theme.breakpoint.s}) {
    ${props => props.theme.featureHeight.l}
    min-height:${props => props.theme.featureHeight.l.height};
    &.hasCaption{
      height:Calc(51vh - 2.88rem - 50px);
      min-height:Calc(51vh - 2.88rem - 50px);
    }
  }
`;


const ItemSubtitle = styled.div`
  color: ${props => props.theme.colors.greyMedium};
`;

class CarouselImage  extends React.Component  {
  render(){
    const {carousel_image, carousel_caption, isHero } = this.props;
    let hasCaption = false;
    if (carousel_caption){
      hasCaption = (carousel_caption["text"] && isHero != "Yes");
    }

    return(
      <div>
        <Item flexDirection='column' className={(hasCaption)?"hasCaption":""}>
          <Img className="carouselImg" fluid={carousel_image.localFile.childImageSharp.fluid} />
        </Item>

        {hasCaption &&
          <ImageCaption data={carousel_caption}></ImageCaption>
        }
      </div>
    )
  }
}



export default CarouselImage;

CarouselImage.propTypes = {
  carousel_image: PropTypes.object.isRequired,
  carousel_caption: PropTypes.object
};
