/****************

Description: end leaf page for projects. e.g. /projects/production/acura-what-a-race1/

*****************/
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styled from 'react-emotion';
import { Box } from 'grid-emotion';
import ReactHtmlParser from 'react-html-parser';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import config from '../../config/website';
import Carousel from '../components/Carousel';
import ProjectTable from '../components/ProjectTable';
import ProjectSiblingNavigation from '../components/ProjectSiblingNavigation';
import ContentSection from '../components/ContentSection';

const Hero = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .gatsby-image-outer-wrapper {
    position: static !important;
    > div {
      position: static !important;
    }
  }
`;

const Wrapper = styled(Box)`
  max-width: ${props => props.theme.maxWidth};

`;

const TitleWrapper = styled(Box)`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.bg};
`;

const Title = styled.h1`
  color: ${props => props.theme.colors.text};
  max-width: ${props => props.theme.maxWidthText};
  text-align: center;
  margin: 0 auto;
  padding: 0 32px;
`;

const SubTitle = styled.h3`
  color: ${props => props.theme.colors.text};
  max-width: ${props => props.theme.maxWidthText};
  margin: 0 auto;
  text-align: center;
`;

const Content = styled.main`
  margin-top: 9rem;
  margin-bottom: 9rem;
  p {
    text-align: justify;
  }
  p,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  blockquote {
    max-width: ${props => props.theme.maxWidthText};
    margin-left: auto;
    margin-right: auto;
  }
  li {
    margin-left: 1.45rem;
  }
  .block-img {
    max-width: 100%;
    margin-top: 6rem;
    margin-bottom: 6rem;
    text-align: center;
  }
  @media (max-width: ${props => props.theme.breakpoint.m}) {
    margin-top: 6rem;
    margin-bottom: 6rem;
    .block-img {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
  }
`;

const ProjectTemplate = ({pageContext,  data: { prismicProject: projectNode, allPrismicProject: allCategoryProject } }) => {
  const { data } = projectNode;

  let categoryRelativeIndex = -1;
  allCategoryProject.edges.map((project, idx)=>{
    if (project.node.uid === projectNode.uid){
      categoryRelativeIndex = idx;
      return;
    }
  });

  let linkNext = (categoryRelativeIndex>0)?"/projects/"+pageContext.slugifiedTag+"/"+allCategoryProject.edges[categoryRelativeIndex-1].node.uid:"";
  let linkPrevious = (categoryRelativeIndex<allCategoryProject.edges.length -1)?"/projects/"+pageContext.slugifiedTag+"/"+allCategoryProject.edges[categoryRelativeIndex+1].node.uid:"";

  const isPortfolio = (projectNode.tags.indexOf("vault") == -1)?false:true;

  if (data.body1 == null){
    return (<h1>body1 is null</h1>)
  }
  return (
    <Layout isProject>

      <Helmet title={`${data.project_name} | ${config.siteTitle}`} />
      <SEO projectNode={projectNode} projectPath={projectNode.uid} projectSEO />
      {data.body1.map((slice, idx) => {
        switch (slice.__typename) {
          case 'PrismicProjectBody1ImageCarousel':
            return <Carousel slugifiedTag={pageContext.slugifiedTag} key={slice.id} data={slice} project={data} />
          case 'PrismicProjectBody1ProjectDescription':
            return <ProjectTable isPortfolio={isPortfolio} data={slice} key={slice.id} heading={slice.primary.description_heading} rows={slice.items} description={slice.primary.project_description} project_image={slice.primary.project_description_image} />
          case "PrismicProjectBody1ContentSection":
            return <ContentSection  key={slice.id} data={slice} />
          default:
            return <div key={idx}>unknown slice type {slice.__typename}</div>
          }
        })}
        <ProjectSiblingNavigation {...projectNode} linkNext={linkNext}  linkPrevious={linkPrevious} />
    </Layout>
  );
};

export default ProjectTemplate;

ProjectTemplate.propTypes = {
  data: PropTypes.shape({
    prismicProject: PropTypes.object.isRequired,
  }).isRequired,
};
/*

query s2 ($tag: String!){
      allPrismicProject (filter: { tags: { in: $tag } }, sort: { fields: [last_publication_date], order: DESC }) {{
        edges {
          node {
            id
            uid
          }
        }
      }
    }
*/
export const pageQuery = graphql`
  query ProjectBySlug($uid: String!, $tag: [String]) {
    allPrismicProject(filter: { tags: { in: $tag, nin: "unpublished"} }, sort: { fields: [last_publication_date], order: DESC }) {
      edges {
        node {
          id
          uid
        }
      }
    }
    prismicProject(uid: { eq: $uid }) {
      uid
      id
      last_publication_date
      first_publication_date
      tags
      data {
        project_name
        client_name {
          html
          text
        }
        description {
          html
        }
        sibling_pages {
          link_colour
          link_label
          link_href
        }
        social_body {
          __typename
          ... on PrismicProjectSocialBodyGeneralCard {
            id
            primary {
              title {
                html
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fixed {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                alt
              }
            }
          }
          ... on PrismicProjectSocialBodyGeneralCard {
            id
            primary {
              title {
                html
                text
              }
              description {
                html
                text
              }
              image {
                alt
                url
              }
            }
          }
        }
        body1 {
          __typename
          ... on PrismicProjectBody1ProjectDescription {
            id
            primary {
              fragment_id
              description_heading {
                html
              }
              project_description {
                html
              }
              project_description_image {
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 90)  {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                url
              }
            }
            items {
              table_heading {
                html
                text
              }
              table_description {
                html
              }
            }
          }
          ... on PrismicProjectBody1ContentSection{
            id
            primary {
              fragment_id
              heading {
                html
                text
              }
              sub_heading {
                html
                text
              }
              section_copy {
                html
                text
              }
              background_image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                    fixed {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              box_colour
              media_alignment
            },
            items{
              gallery_image {
                alt
                copyright
                url
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                    fixed {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              percentage_width
              image_caption {
                html
                text
              }
              video_embed {
                html
                text
              }
              video_id{
                text
              }
            }
          }
          ... on PrismicProjectBody1ImageCarousel {
            id
            primary{
              hero_status
              fragment_id
            }
            items{
              video_embed{
                text
              }
              video_id{
                text
              }
              carousel_caption{
                text
              }
              carousel_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1920, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                url
              }
            }
          }
        }
      }
    }
  }
`;
